const MESSAGE = {
  EN: {
    range_name_label: 'Color detection name',
    range_name_placeholder: 'Enter color detection name',
    color_range_dropdown_label: 'Color range',
    cancel_button: 'Cancel',
    create_button: 'Create',
    save_button: 'Save',
    action_edit: 'Edit',
    action_delete: 'Delete',
    range_name_error: 'Sorry, this name is already used. Please try another name',
    duplicate_hue_error: 'Sorry, this color range is already used, Please try another range',
    duplicate_number_error: 'Sorry, this hue value is already used, Please try another value',
    out_of_range_hue_error: 'Sorry, only number (0-359) are allowed, Please try another range',
    hue_same_no_error: "Sorry, the color range can't be used by the same number.  Please try another range",
    empty_hue_error: `Please add the number from 0 - 359 
    or pick the color from palatte`
  },
  TH: {
    range_name_label: 'ชื่อสีที่ตรวจจับ',
    range_name_placeholder: 'ใส่ชื่อสีที่ตรวจจับ',
    color_range_dropdown_label: 'ช่วงสี',
    cancel_button: 'ยกเลิก',
    create_button: 'สร้าง',
    save_button: 'บันทึก',
    action_edit: 'แก้ไข',
    action_delete: 'ลบ',
    range_name_error: 'ขออภัย ชื่อนี้ถูกใช้งานแล้ว กรุณาใช้ชื่ออื่น',
    duplicate_hue_error: 'ขออภัย ช่วงสีนี้ถูกใช้งานแล้ว กรุณาใช้ช่วงสีอื่น',
    duplicate_number_error: 'ขออภัย เลขค่าสีนี้ถูกใช้งานแล้ว กรุณาใช้ค่าอื่น',
    out_of_range_hue_error: 'ขออภัย อนุญาตให้ใช้เฉพาะตัวเลข (0-359) เท่านั้น กรุณาใช้ช่วงสีอื่น',
    hue_same_no_error: 'ขออภัย ช่วงสีไม่สามารถใช้ค่าตัวเลขเดียวกันได้ กรุณาใช้ช่วงสีอื่น',
    empty_hue_error: `กรุณาใส่ค่าตัวเลขตั้งแต่ 0 - 359
    หรือ คลิกเพื่อเลือกสีจากพาเลท`
  }
}

export default MESSAGE
