import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { HuePicker } from 'react-color'
import Dropdown from '../Dropdown/Loadable'
import TextInput from '../TextInput/Loadable'
import Button from '../Button/Loadable'

import ColorRangeStyled from './styledComponent'
import KebabMenuIcon from '../../asset/images/button/kebab-menu.svg'
import KebabMenuActiveIcon from '../../asset/images/button/kebab-menu-active.svg'
import MESSAGE from './message'

const LEFT_SIDE = 'left'
const RIGHT_SIDE = 'right'
const BACKSPACE_KEY_CODE = 8
const DELETE_KEY_CODE = 46

const ColorRangePicker = ({
  className,
  colorRangeList,
  activeColor,
  language,
  onColorRangeEdit,
  onColorRangeDelete,
  shouldHideAddColorRangeButton,
  checkWhetherColorNameDuplicate,
  savedColorRangeList,
  revertBackColorRange,
  setChangeSetting
}) => {
  const wrapperRef = useRef(null)
  const pickerRef = useRef(null)
  const leftInputRef = useRef(null)
  const rightInputRef = useRef(null)
  const canvasNormalModeRef = useRef(null)
  const canvasEditModeRef = useRef(null)
  clickOutsideWatcher(wrapperRef)
  clickOutsideWatcher(pickerRef)

  const [showActionPopup, setShowActionPopup] = useState(false)
  const [isEditMode, setIsEditMode] = useState(activeColor.range_name === '')
  const [isShowPicker, setShowPicker] = useState(true)
  const [intActiveColor, setIntActiveColor] = useState({
    name: activeColor.name,
    range_name: activeColor.range_name,
    left: { h: activeColor.min_hue || 0, s: 1, l: 0.5, a: 1 },
    right: { h: activeColor.max_hue || 1, s: 1, l: 0.5, a: 1 }
  })
  const [activeSide, setActiveSide] = useState(LEFT_SIDE)
  const [internalColorList, setInternalColorList] = useState([])
  const [rangeNameErrorText, setRangeNameErrorText] = useState('')
  const [hueErrorText, setHueErrorText] = useState('')

  function clickOutsideWatcher(ref) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeActionPopup()
          closeHuePicker()
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const showActionIcon = () => {
    return showActionPopup ? KebabMenuActiveIcon : KebabMenuIcon
  }
  const closeActionPopup = () => setShowActionPopup(false)
  const onKebabButtonClick = () => setShowActionPopup(true)

  const onEditActionClick = () => {
    shouldHideAddColorRangeButton(true)
    setIsEditMode(true)
    setChangeSetting(true)
    closeActionPopup()
  }

  const onDeleteActionClick = () => {
    onColorRangeDelete(activeColor.cid, activeColor.range_name)
    closeActionPopup()
  }

  const getMessage = useCallback(
    (property) => {
      const msg = MESSAGE[language]
      return msg[property]
    },
    [language]
  )

  const drawNormalDirection = (ctx, gradient, min_hue, max_hue) => {
    const hueDiff = max_hue - min_hue
    for (let i = 0; i <= 1; i += 0.1) {
      gradient.addColorStop(i, `hsl(${(min_hue + i * hueDiff) % 360}, 100%, 50%)`)
    }
    ctx.fillStyle = gradient
    return ctx
  }

  const drawReverseDirection = (ctx, gradient, min_hue, max_hue) => {
    const hueDiff = 360 - min_hue + max_hue
    for (let i = 0; i <= 1; i += 0.1) {
      gradient.addColorStop(i, `hsl(${(min_hue + i * hueDiff) % 360}, 100%, 50%)`)
    }
    ctx.fillStyle = gradient
    return ctx
  }

  const drawBar = useCallback(() => {
    const { left, right } = intActiveColor
    const canvas = canvasNormalModeRef.current
    if (canvas !== null) {
      let ctx = canvas.getContext('2d')
      const gradient = ctx.createLinearGradient(0, 0, 138, 16)
      if (right.h > left.h) {
        ctx = drawNormalDirection(ctx, gradient, left.h, right.h)
      } else if (left.h > right.h) {
        ctx = drawReverseDirection(ctx, gradient, left.h, right.h)
      }
      ctx.fillRect(0, 0, 138, 16)
    }
  }, [intActiveColor])

  const drawbarEditMode = useCallback(() => {
    const { left, right } = intActiveColor
    const canvas = canvasEditModeRef?.current
    if (canvas !== null) {
      let ctx = canvas.getContext('2d')
      const gradient = ctx.createLinearGradient(0, 0, 121, 19)
      if (right.h > left.h) {
        ctx = drawNormalDirection(ctx, gradient, left.h, right.h)
      } else if (left.h > right.h) {
        ctx = drawReverseDirection(ctx, gradient, left.h, right.h)
      } else if (left.h === null && right.h === null) {
        // case no colors on both side
        ctx = drawNormalDirection(ctx, gradient, 0, 359)
      }
      ctx.fillRect(0, 0, 121, 19)
    }
  }, [intActiveColor])

  const checkIfColorisInRange = useCallback(
    (leftHue, rightHue) => {
      const color = colorRangeList.find((eachColor) => eachColor.min_hue === leftHue && eachColor.max_hue === rightHue)
      return color !== undefined
    },
    [colorRangeList]
  )

  const checkWhetherNumberIsInRange = (num) => {
    return num >= 0 && num < 360
  }

  const checkWhetherNotTheSameNumber = (left_hue, right_hue) => {
    return left_hue !== right_hue
  }

  const checkWhetherHueExist = useCallback(() => {
    let color = undefined
    const { left, right } = intActiveColor
    color = savedColorRangeList.find(
      (eachColor) => eachColor.min_hue === left.h && eachColor.max_hue === right.h && eachColor.cid !== activeColor.cid
    )
    return typeof color !== 'undefined'
  }, [activeColor.cid, intActiveColor, savedColorRangeList])

  const checkLeftHueExist = useCallback(
    (leftHue) => {
      const existColor = savedColorRangeList.find((eachColor) => eachColor.min_hue === leftHue && eachColor.cid !== activeColor.cid)
      return typeof existColor !== 'undefined'
    },
    [activeColor.cid, savedColorRangeList]
  )

  const checkRightHueExist = useCallback(
    (rightHue) => {
      const existColor = savedColorRangeList.find((eachColor) => eachColor.max_hue === rightHue && eachColor.cid !== activeColor.cid)
      return typeof existColor !== 'undefined'
    },
    [activeColor.cid, savedColorRangeList]
  )

  const checkAndSetHueErrorText = useCallback(() => {
    const { left, right, range_name } = intActiveColor
    let errorText = ''
    if (checkWhetherHueExist()) {
      errorText = getMessage('duplicate_hue_error')
    } else if (checkLeftHueExist(left.h) || checkRightHueExist(right.h)) {
      errorText = getMessage('duplicate_number_error')
    } else if (!checkWhetherNumberIsInRange(left.h) || !checkWhetherNumberIsInRange(right.h)) {
      errorText = getMessage('out_of_range_hue_error')
    } else if (left.h === null && right.h === null) {
      errorText = getMessage('empty_hue_error')
    } else if (!checkWhetherNotTheSameNumber(left.h, right.h)) {
      errorText = getMessage('hue_same_no_error')
    } else if (checkWhetherColorNameDuplicate(range_name, activeColor.cid)) {
      setRangeNameErrorText(getMessage('range_name_error'))
    }
    setHueErrorText(errorText)
  }, [intActiveColor, checkWhetherHueExist, checkLeftHueExist, checkRightHueExist, checkWhetherColorNameDuplicate, activeColor.cid, getMessage])

  useEffect(() => {
    checkAndSetHueErrorText()
  }, [checkAndSetHueErrorText])

  useEffect(() => {
    if (isEditMode) {
      drawbarEditMode()
    } else {
      drawBar()
    }
  }, [drawBar, drawbarEditMode, activeColor.cid, intActiveColor, isEditMode])

  useEffect(() => {
    setIntActiveColor({
      name: activeColor.name,
      range_name: activeColor.range_name,
      left: { h: activeColor.min_hue, s: 1, l: 0.5, a: 1 },
      right: { h: activeColor.max_hue, s: 1, l: 0.5, a: 1 }
    })
  }, [activeColor.cid, activeColor.max_hue, activeColor.min_hue, activeColor.name, activeColor.range_name])

  useEffect(() => {
    const listMod = colorRangeList.map((color) => {
      return { text: color.name, value: color.name }
    })
    setInternalColorList(listMod)
    shouldHideAddColorRangeButton(isEditMode)
  }, [colorRangeList, intActiveColor, checkIfColorisInRange, shouldHideAddColorRangeButton, isEditMode])

  const renderActionPopup = () => {
    let output = null
    if (showActionPopup) {
      output = (
        <div className="action-popup">
          <div onClick={onEditActionClick} id="kebab-btn-edit">
            {getMessage('action_edit')}
          </div>
          <div onClick={onDeleteActionClick} id="kebab-btn-delete">
            {getMessage('action_delete')}
          </div>
        </div>
      )
    }
    return output
  }

  const renderColorRangeCard = () => {
    const { name, range_name, left, right } = intActiveColor
    const { cid } = activeColor
    return (
      <div className="card-wrapper">
        <div className="card-wrapper-header">
          <span className="card-wrapper-header-title">{range_name}</span>
          <span className="card-wrapper-header-btn" id="kebab-btn" onClick={onKebabButtonClick}>
            <img src={showActionIcon()} alt="" />
          </span>
          {renderActionPopup()}
        </div>
        <div className="card-wrapper-body">
          <span className="card-wrapper-body-label">{name}</span>
          <canvas id={`color-bar-${cid}`} width={138} height={16} ref={canvasNormalModeRef} />
          <div className="card-wrapper-body-color-value">
            <span>{Math.round(left.h)}</span>
            <span>{Math.round(right.h)}</span>
          </div>
        </div>
      </div>
    )
  }

  const onInputChange = (e) => {
    setIntActiveColor({ ...intActiveColor, range_name: e.target.value })
    let errorText = ''
    if (checkWhetherColorNameDuplicate(e.target.value, activeColor.cid)) {
      errorText = getMessage('range_name_error')
    }
    setRangeNameErrorText(errorText)
  }

  const renderRangeNameInput = () => {
    const { range_name } = intActiveColor
    return (
      <div className="range-picker-block-input-wrapper">
        <TextInput
          id="range-name-input"
          mode={'normal'}
          onChange={onInputChange}
          value={range_name}
          label={`${getMessage('range_name_label')} *`}
          placeHolder={getMessage('range_name_placeholder')}
          errorText={rangeNameErrorText}
        />
      </div>
    )
  }

  const onDropdownColorChange = (color) => {
    const specificColor = colorRangeList.find((colorRange) => colorRange.name === color.value)
    if (typeof specificColor !== 'undefined') {
      setIntActiveColor({
        ...intActiveColor,
        name: color.value,
        left: { ...intActiveColor.left, h: specificColor.min_hue },
        right: { ...intActiveColor.left, h: specificColor.max_hue }
      })
      setActiveSide(LEFT_SIDE)
      setShowPicker(true)
    }
  }

  const renderListColorDropdown = () => {
    return (
      <div className="range-picker-block-dropdown-wrapper">
        <Dropdown
          id="color-range-dropdown"
          label={getMessage('color_range_dropdown_label')}
          labelPosition="top"
          buttonColor={'#27a448'}
          options={internalColorList}
          value={intActiveColor.name}
          onChange={onDropdownColorChange}
          language={language}
        />
      </div>
    )
  }

  const onColorBlockClick = (side) => {
    setActiveSide(side)
    setShowPicker(true)
  }

  const closeHuePicker = () => {
    setShowPicker(false)
    setActiveSide('')
  }

  const changeColorNameBasedOnColor = (side, hue) => {
    let name = 'Custom'
    if (side === LEFT_SIDE) {
      const possibleColor = colorRangeList.find((color) => color.min_hue === hue)
      if (typeof possibleColor !== 'undefined' && possibleColor.max_hue === intActiveColor[RIGHT_SIDE].h) {
        name = possibleColor.name
      }
    } else {
      const possibleColor = colorRangeList.find((color) => color.max_hue === hue)
      if (typeof possibleColor !== 'undefined' && possibleColor.min_hue === intActiveColor[LEFT_SIDE].h) {
        name = possibleColor.name
      }
    }
    return name
  }

  const onPickerChange = (color, event) => {
    const tempIntActiveColor = { ...intActiveColor, [activeSide]: color.hsl, name: changeColorNameBasedOnColor(activeSide, Math.round(color.hsl.h)) }
    setIntActiveColor(tempIntActiveColor)
    checkAndSetHueErrorText()
  }

  const onLeftInputColorChange = (e) => {
    const hue = Math.round(e.target.value)
    const tempIntActiveColor = { ...intActiveColor, left: { ...intActiveColor.left, h: hue }, name: changeColorNameBasedOnColor(LEFT_SIDE, hue) }
    setIntActiveColor(tempIntActiveColor)
    checkAndSetHueErrorText()
    setActiveSide(LEFT_SIDE)
  }

  const onRightInputColorChange = (e) => {
    const hue = Math.round(e.target.value)
    const tempIntActiveColor = {
      ...intActiveColor,
      right: { ...intActiveColor.right, h: hue },
      name: changeColorNameBasedOnColor(RIGHT_SIDE, hue)
    }
    setIntActiveColor(tempIntActiveColor)
    checkAndSetHueErrorText()
    setActiveSide(RIGHT_SIDE)
  }

  const renderHuePicker = () => {
    let output = null
    if (isShowPicker) {
      output = (
        <div className="hue-picker-wrapper" ref={pickerRef}>
          <HuePicker onChange={onPickerChange} color={intActiveColor[activeSide]} width={190} height={15} />
        </div>
      )
    }
    return output
  }

  const checkErrorLeftInput = (hue) => {
    const roundHue = Math.round(hue)
    return (
      checkLeftHueExist(roundHue) ||
      !checkWhetherNumberIsInRange(roundHue) ||
      !checkWhetherNotTheSameNumber(roundHue, intActiveColor.right.h) ||
      hue === null
    )
  }

  const checkErrorRightInput = (hue) => {
    const roundHue = Math.round(hue)
    return (
      checkRightHueExist(roundHue) ||
      !checkWhetherNumberIsInRange(roundHue) ||
      !checkWhetherNotTheSameNumber(roundHue, intActiveColor.left.h) ||
      hue === null
    )
  }

  const onKeyDownHandler = (e) => {
    const value = e.target.value
    if ((e.keyCode === BACKSPACE_KEY_CODE || e.keyCode === DELETE_KEY_CODE) && (value === '0' || value === '') && e.target === leftInputRef.current) {
      const tempIntActiveColor = { ...intActiveColor, left: { ...intActiveColor.left, h: null } }
      setIntActiveColor(tempIntActiveColor)
    }
    if (
      (e.keyCode === BACKSPACE_KEY_CODE || e.keyCode === DELETE_KEY_CODE) &&
      (value === '0' || value === '') &&
      e.target === rightInputRef.current
    ) {
      const tempIntActiveColor = { ...intActiveColor, right: { ...intActiveColor.right, h: null } }
      setIntActiveColor(tempIntActiveColor)
    }
  }

  const renderColorRangePicker = () => {
    const { left, right } = intActiveColor
    const { cid } = activeColor
    const leftColorClassName = activeSide === LEFT_SIDE ? 'left-active' : ''
    const rightColorClassName = activeSide === RIGHT_SIDE ? 'right-active' : ''
    const leftInputClassName = checkErrorLeftInput(left.h) ? 'hue-input-error' : ''
    const rightInputClassName = checkErrorRightInput(right.h) ? 'hue-input-error' : ''
    const backgroundLeftColor = left.h === null ? 'transparent' : `hsl(${left.h}, ${left.s * 100}%, ${left.l * 100}%)`
    const backgroundRightColor = right.h === null ? 'transparent' : `hsl(${right.h}, ${right.s * 100}%, ${right.l * 100}%)`

    if (intActiveColor.name !== '') {
      return (
        <div className="range-picker-block-color-wrapper">
          {renderHuePicker()}
          <div className="range-picker-block-color-wrapper-top">
            <div
              className={`color-block ${leftColorClassName}`}
              id="left-color-block"
              onClick={() => onColorBlockClick(LEFT_SIDE)}
              style={{ backgroundColor: backgroundLeftColor }}
            >
              &nbsp;
            </div>
            <canvas id={`color-bar-edit-mode-${cid}`} width={121} height={19} ref={canvasEditModeRef} />
            <div
              className={`color-block ${rightColorClassName}`}
              id="right-color-block"
              onClick={() => onColorBlockClick(RIGHT_SIDE)}
              style={{ backgroundColor: backgroundRightColor }}
            >
              &nbsp;
            </div>
          </div>
          <div className="range-picker-block-color-wrapper-bottom">
            <input
              id="left-color-input"
              ref={leftInputRef}
              className={leftInputClassName}
              min={0}
              max={360}
              type="number"
              onChange={onLeftInputColorChange}
              value={left.h === null ? '' : Math.round(left.h)}
              onKeyDown={onKeyDownHandler}
            />
            <input
              id="right-color-input"
              ref={rightInputRef}
              className={rightInputClassName}
              mode="normal"
              min={0}
              max={360}
              type="number"
              onChange={onRightInputColorChange}
              value={right.h === null ? '' : Math.round(right.h)}
              onKeyDown={onKeyDownHandler}
            />
          </div>
          <div className="hue-error-text">{hueErrorText}</div>
        </div>
      )
    }
  }

  const shouldDisabledCreateBtn = () => {
    const { left, right } = intActiveColor
    return (
      intActiveColor.range_name === '' ||
      hueErrorText !== '' ||
      rangeNameErrorText !== '' ||
      intActiveColor.name === '' ||
      checkErrorLeftInput(left.h) ||
      checkErrorRightInput(right.h)
    )
  }

  const onCancelBtnClick = () => {
    shouldHideAddColorRangeButton(false)
    const originalIntColor = {
      name: activeColor.name,
      range_name: activeColor.range_name,
      left: { h: activeColor.min_hue, s: 1, l: 0.5, a: 1 },
      right: { h: activeColor.max_hue, s: 1, l: 0.5, a: 1 }
    }
    setIntActiveColor(originalIntColor)
    revertBackColorRange(activeColor.cid)
    setHueErrorText('')
    setRangeNameErrorText('')
    setIsEditMode(false)
    setChangeSetting(false)
  }

  const onCreateBtnClick = () => {
    const { left, right, range_name, name } = intActiveColor
    const color = { min_hue: Math.round(left.h), max_hue: Math.round(right.h), name, range_name, cid: activeColor.cid }
    let errorText = ''
    if (checkWhetherColorNameDuplicate(range_name, activeColor.cid)) {
      errorText = getMessage('range_name_error')
    } else {
      shouldHideAddColorRangeButton(false)
      onColorRangeEdit(color)
      setIsEditMode(false)
      setChangeSetting(true)
    }
    setRangeNameErrorText(errorText)
  }

  const renderButtons = () => {
    const createBtnText = activeColor.create ? getMessage('create_button') : getMessage('save_button')
    return (
      <div className="range-picker-block-button-wrapper">
        <Button id="cancel-btn" className="cancel-btn" text={getMessage('cancel_button')} onClick={onCancelBtnClick} invert />
        <Button id="create-btn" className="create-btn" text={createBtnText} onClick={onCreateBtnClick} disabled={shouldDisabledCreateBtn()} />
      </div>
    )
  }

  const renderColorRangeEditMode = () => {
    return (
      <div className="range-picker-block">
        {renderRangeNameInput()}
        {renderListColorDropdown()}
        {renderColorRangePicker()}
        {renderButtons()}
      </div>
    )
  }

  const renderColorRange = () => {
    let output = null
    if (isEditMode) {
      output = renderColorRangeEditMode()
    } else {
      output = renderColorRangeCard()
    }
    return output
  }

  return (
    <ColorRangeStyled className={className} ref={wrapperRef}>
      {renderColorRange()}
    </ColorRangeStyled>
  )
}

ColorRangePicker.defaultProps = {
  colorRangeList: [
    { name: 'Orange shades', range_name: 'Orange', min_hue: 15, max_hue: 34, cid: 1 },
    { name: 'Yellow shades', range_name: 'Yellow', min_hue: 35, max_hue: 69, cid: 2 },
    { name: 'Green shades', range_name: 'Green', min_hue: 70, max_hue: 184, cid: 3 },
    { name: 'Blue shades', range_name: 'Blue', min_hue: 185, max_hue: 259, cid: 4 },
    { name: 'Purple shades', range_name: 'Purple', min_hue: 260, max_hue: 299, cid: 5 },
    { name: 'Red shades', range_name: 'Red', min_hue: 300, max_hue: 14, cid: 6 }
  ],
  activeColor: { name: 'Green shades', range_name: 'Green', min_hue: 70, max_hue: 179, cid: 3 }
}

ColorRangePicker.propTypes = {
  className: PropTypes.string,
  colorRangeList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      range_name: PropTypes.string,
      min_hue: PropTypes.number,
      max_hue: PropTypes.number
    })
  ).isRequired,
  savedColorRangeList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      range_name: PropTypes.string,
      min_hue: PropTypes.number,
      max_hue: PropTypes.number,
      cid: PropTypes.number
    })
  ).isRequired,
  activeColor: PropTypes.shape({
    name: PropTypes.string,
    range_name: PropTypes.string,
    min_hue: PropTypes.number,
    max_hue: PropTypes.number,
    cid: PropTypes.number
  }).isRequired,
  onColorRangeEdit: PropTypes.func.isRequired,
  onColorRangeDelete: PropTypes.func.isRequired,
  shouldHideAddColorRangeButton: PropTypes.func.isRequired,
  checkWhetherColorNameDuplicate: PropTypes.func.isRequired,
  setChangeSetting: PropTypes.func.isRequired,
  revertBackColorRange: PropTypes.func.isRequired,
  language: PropTypes.oneOf(['EN', 'TH'])
}

export default ColorRangePicker
