import styled from 'styled-components/macro'

export default styled.div`
  width: 100%;
  .card-wrapper {
    height: 109px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 6px 12px;
    display: flex;
    flex-direction: column;
    font-family: 'Prompt', sans-serif;
    font-size: 12px;
    color: ${(props) => props.theme.darkBackground};
    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      &-btn {
        width: 18px;
        height: 18px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .action-popup {
        position: absolute;
        right: -48px;
        top: 24px;
        width: 68px;
        z-index: 1;
        background: #fff;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        div {
          font-family: 'Prompt', sans-serif;
          font-size: 13px;
          padding: 10px;
          &:hover {
            cursor: pointer;
            background-color: #eeeeee;
          }
        }
      }
    }
    &-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      &-label {
        font-family: 'Prompt', sans-serif;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 12px;
      }
      &-color-value {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .range-picker-block {
    display: flex;
    flex-direction: column;
    &-input-wrapper {
      margin-bottom: 32px;
      .label-wrapper {
        font-family: 'Prompt-Medium', sans-serif;
        color: ${(props) => props.theme.darkBackground};
        font-size: 12px !important;
        font-weight: normal !important;
      }
      #range-name-input {
        width: 100%;
        height: 30px;
        ::-webkit-input-placeholder {
          font-family: 'Prompt', sans-serif;
          color: ${(props) => props.theme.placeholderColor};
          font-size: 12px;
        }
        ::-moz-placeholder {
          font-family: 'Prompt', sans-serif;
          color: ${(props) => props.theme.placeholderColor};
          font-size: 12px;
        }
      }
    }
    &-dropdown-wrapper {
      margin-bottom: 45px;
      .label-wrapper {
        font-family: 'Prompt-Medium', sans-serif;
        color: ${(props) => props.theme.darkBackground};
        font-size: 12px !important;
        font-weight: normal !important;
      }
    }
    &-color-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .hue-picker-wrapper {
        position: absolute;
        top: -28px;
        width: 190px;
        height: 15px;
        border-radius: 1px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      }

      &-top {
        display: flex;
        align-items: center;
        .color-block {
          width: 26px;
          height: 26px;
          border-radius: 5px;
          border: 1px solid rgb(39, 164, 72);
          cursor: pointer;
          &:first-child {
            margin-right: 4px;
          }
          &:last-child {
            margin-left: 4px;
          }
          &.left-active,
          &.right-active {
            border: 2px solid rgb(39, 164, 72);
            border-radius: 5px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
          }
        }
        canvas {
          width: 135px;
          height: 19px;
        }
      }
      &-bottom {
        width: 103%;
        position: relative;
        height: 30px;
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        margin-left: -5px;
        input {
          font-family: 'Prompt', sans-serif;
          font-size: 12px;
          width: 35px;
          height: 30px;
          border-radius: 5px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
          padding: 0;
          text-align: center;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          border: solid 1px ${(props) => props.theme.greyDisabled};
          outline: none;
          &:focus {
            border: 1px solid ${(props) => props.theme.jadeGreen};
          }
          &.hue-input-error {
            border: 1px solid #d0061f;
          }
        }
      }
    }
    .hue-error-text {
      font-size: 10px;
      width: 100%;
      white-space: pre-line;
      position: absolute;
      top: 68px;
      color: ${(props) => props.theme.redFont};
    }
    &-button-wrapper {
      width: 200px;
      margin-top: 62px;
      height: 30px;
      display: flex;
      justify-content: space-between;
      button {
        font-family: 'Prompt', sans-serif;
        font-size: 12px;
        height: 100%;
        width: 91px;
      }
    }
  }
`
